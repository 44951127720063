:root {
  --bluegray: rgb(162, 185, 188);
  --background: rgb(23, 38, 34);
  --white: rgb(245, 246, 247);
  --darkgray: rgb(99, 112, 126);
  --purple: rgb(107, 91, 149);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100vh;
}

body {
  background: var(--background);
  color: var(--white);
  font-family: 'Raleway';
}

/* Background image */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -9999;
  background-image: url(./assets/images/hero.jpg);
  background-repeat: no-repeat;
  background-position: bottom left;
  opacity: 0.4;
}

/* Header rules */
header {
  padding: 20px 50px;
  background-color: var(--white);
  color: var(--purple);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

header h1 {
  font-size: 48px;
  margin: 0;
}

header nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

a {
  color: var(--darkgray);
  padding: 5px 15px;
  text-decoration: none;
  font-size: 30px;
  font-style: normal;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

/* Content container */
main {
  max-width: 85%;
  margin: 0 auto;
}

section {
  margin: 60px 0;
  padding: 10px 0;
  display: flex;
  flex-flow: row;
  align-items: stretch;
  justify-content: flex-start;
}

/* Title and picture rules */
.left {
  border-right: 4px solid var(--purple);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-right: 15px;
  padding: 30px 0;
  font-size: 2vw;
  line-height: 1.1;
  width: 25%;
  text-shadow: 2px 2px 1px var(--purple);
}

#contact-me {
  margin-bottom: 0px;
}

img {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: right;
}

/* "About Me" rules */
.description {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: flex-start;
  width: 75%;
}

.description p {
  font-size: 20px;
  line-height: 1.6;
  text-shadow: 2px 2px 5px var(--purple);
}

/* "Recent Work" rules */
.projects {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(12, 1fr); 
  grid-auto-rows: minmax(150px, 1fr);
  gap: 10px;
  width: 75%;
}

/* Grid rules for recent works */
.project {
  grid-column: span 4;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 150px;
  min-width: 150px;
  background-color: var(--bluegray);
  background-blend-mode: multiply;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  padding-left: 2%;
}

/* Showcase first project */
.project:first-child {
  grid-column: span 8;
  grid-row: span 2;
}

.project-description {
  padding: 10px;
  background: var(--purple);
  border-radius: 5px;
}

.project-name {
  color: var(--white);
  font-size: 20px;
  text-shadow: 2px 2px 5px var(--purple);
}

.project-tools {
  color: var(--white);
  font-size: 15px;
  text-shadow: 2px 2px 5px var(--purple);
}

/* Project screenshots */
.nomadica {
  background-image: url(./assets/images/nomadica.jpg);
}

.weather-dashboard {
  background-image: url(./assets/images/weather-dashboard.jpg);
}

.food-festival {
  background-image: url(./assets/images/food-festival.jpg);
}

.minnow {
  background-image: url(./assets/images/minnow.jpg);
}

.gaming-survey {
  background-image: url(./assets/images/gaming-survey.jpg);
}

.tv-tracker {
  background-image: url(./assets/images/tv-tracker.jpg);
}

/* "Contact Me" rules */
form {
  width: 600px;
}

form div {
  margin-bottom: 8px;
}

label {
  display: block;
  font-size: 22px;
}

input {
  width: 100%;
  font-size: 1rem;
  padding: 0.25rem;
  border: 2px solid var(--darkgray);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus,
textarea:focus {
  border: 2px solid var(--purple);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

textarea {
	width: 100%;
	height: 120px;
	border: 2px solid var(--darkgray);
  padding: 5px;
  resize: vertical;
}

button {
  font-size: 22px;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  color: var(--white);
  background-color: var(--purple);
  width: 100%;
}

button:hover {
  opacity: .8;
}

.contact-info {
  width: 75%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.contact a {
  color: var(--white);
  text-shadow: 2px 2px 5px var(--purple);
}

.big {
  display: inline-block;
  font-size: 20px;
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: bottom;
}

.small-window {
  display: none;
}

/* Resume Rules */
.resume {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.resume p,
.resume a {
  font-size: 20px;
  color: white;
}

.resume a {
  padding: 0;
  text-decoration: underline;
  position: relative;
  display: inline;
}

.resume div {
  margin-top: 16px;
}

.resume h2 {
  font-size: 26px;
  text-shadow: 2px 2px 1px var(--purple);
  margin-bottom: 8px;
}

.resume ul {
  /* list-style: none; */
  list-style-position: inside;
}

.resume li {
  font-size: 20px;
  margin-bottom: 3px;
}

/* Footer Rules */
body {
  padding-bottom: 10rem;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 10rem;
}

/* Nav Link Hover Animation */
nav a {
  position: relative;
}

nav a:before {
  content:"";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--purple);
  opacity: 0.8;
  transform: scaleY(0);
  transform-origin: 50% 0;
  transition: transform 0.3s ease-out;
}

nav a:hover:before {
  transform: scaleY(1);
}

nav a:hover {
  color: var(--white);
}

.active {
  background: var(--purple);
  opacity: 0.8;
  color: var(--white);
}

/* Recent Work Hover Animation */
.project {
  position: relative;
}

.project:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--purple);
  opacity: 0.6;
  transform: scaleX(0);
  transform-origin: 0%;
  transition-property: transform;
  transition: transform 0.3s ease-out;
}

.project:hover:before {
  transform: scaleX(1);
}

/* Address Link Hover Animation */
.contact-info a {
  position: relative;
}

.contact-info a:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--purple);
  opacity: 0.8;
  transform: scaleX(0);
  transform-origin: 50%;
  transition: transform 0.3s ease-out;
}

.contact-info a:hover:before {
  transform: scaleX(1);
}

@media screen and (max-width:1100px) {
  header {
      justify-content: center;
      display: block;
      text-align: center;
  }

  header nav ul {
      justify-content: center;
  }

  header nav ul li a {
      font-size: 20px;
      text-align: center;
  }

  section {
    margin: 25px 0;
  }

  .contact a {
    font-size: 25px;
  }
}

@media screen and (max-width:980px) {
  body::before {
      background-position: left;
  }

  section {
      display: block;
  }

  .left {
      border-right: none;
      flex-flow: row-reverse;
      align-items: center;
      justify-content: flex-end;
      text-align: left;
      margin-bottom: 15px;
      font-size: 4vw;
      line-height: 1.1;
      width: 100%;
  }

  .left h2 {
      border-bottom: 4px solid var(--purple);
      padding: 0 10px;
  }

  .left img {
      margin-bottom: 10px;
  }

  .description {
      width: 100%;
  }

  .description p {
      padding: 0;
      line-height: 1.2;
      font-size: 18px;
  }

  .projects,
  .contact-info {
      width: 100%;
  }
}

@media screen and (max-width:720px) {
  .contact a {
      font-size: 25px;
  }

  form {
    width: 85vw;
  }
}

@media screen and (max-width:640px) {
  header {
      padding: 5px;
  }

  header h1 {
      font-size: 32px;
  }

  section {
      margin: 20px 0;
  }

  .left {
      width: 100%;
      padding: 0;
  }

  .left img {
      height: 100px;
      width: 100px;
  }

  /* project rows needs to be (# of projects + 1) for correct mobile formatting */
  .projects {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(7, 1fr);
      grid-auto-flow: column;
      gap: 20px;
  }

  .project {
      grid-column: span 8;
      background-repeat: no-repeat;
      width: 100%;
      background-color: var(--bluegray);
      background-blend-mode: multiply;
      background-position: top;
  }

  .contact-info a {
      font-size: 4vw;
  }

  .left {
      font-size: 5vw;
  }
  
  .big-window {
      display: none;
  }

  .small-window {
      display: flex;
  }
}

@media screen and (max-width:580px) {
  .projects {
      gap: 16px;
  }

  body {
    padding-bottom: 5rem;
  }

  .desktop-view {
    display: none;
  }

  footer {
    height: 5rem;
  }
}

@media screen and (max-width: 340px) {
  .left {
      font-size: 4.5vw;
  }
}